// import { combineReducers } from "redux";

import themeReducer from "./themeReducer";
import product from "./product";
import productReviews from "./productReviews";
import category from "./category";
import criteria from "./criteria";
import evaluationCriteria from "./evaluationCriteria";
import article from "./article";
import articleType from "./articleType";
import featured from "./featured";
import manufacturer from "./manufacturer";
import promotion from "./promotion";
import advertisement from "./advertisement";
import auth from "./auth";
import alert from "./alert";
import users from "./users";
import evaluation from "./evaluation";
import productVideo from "./productVideo";
import video from "./video";
import featuredManufacturer from "./featuredManufacturer";
import productImages from "./productImages";
import manufacturerReviews from "./manufacturerReviews";
import distributor from "./distributor";
import productDistributor from "./productDistributor";
import productSku from "./productSku";
import blog from "./blog";
import placement from "./placement";
import Page404And301 from "./404And301";
import embedForm from "./embedForm";
import siteToggle from "./siteToggle";
import articleTemplate from "./articleTemplate";
import dynamicPersona from "./dynamicPersona";
import newsfeed from "./newsfeed";
import teamMembers from "./teamMember";
import popUps from "./popUp";

//Dental Learning Reducers below:
import course from "../redux_DL/reducers/course";
import event from "../redux_DL/reducers/event";
import instructor from "../redux_DL/reducers/instructor";
import categoryDL from "../redux_DL/reducers/category";
import location from "../redux_DL/reducers/location";
import targetAudience from "../redux_DL/reducers/targetAudience";
import courseType from "../redux_DL/reducers/courseType";
import advertisementDL from "../redux_DL/reducers/advertisement";
import embedFormDL from "../redux_DL/reducers/embedForm";
import manufacturerDL from "../redux_DL/reducers/manufacturer";
import shortVideosDL from "../redux_DL/reducers/shortVideos";
import usersDL from "../redux_DL/reducers/user";
import contentFeedDL from "../redux_DL/reducers/contentFeed";
import popupDL from "../redux_DL/reducers/popup";
import featuredDL from "../redux_DL/reducers/featured";
import bundleDL from "../redux_DL/reducers/bundle";
import transactionDL from "../redux_DL/reducers/transaction";
import subscriptionDL from "../redux_DL/reducers/subscription";
import registrationDL from "../redux_DL/reducers/registration"
import bannerDL from "../redux_DL/reducers/banner"
import blogDL from "../redux_DL/reducers/blog"
import articleDL from "../redux_DL/reducers/article"
import courseLicenseDL from "../redux_DL/reducers/courseLicense"
import freeMembershipDL from "../redux_DL/reducers/freeMembership"
import competitorExclusionDL from "../redux_DL/reducers/competitorExclusion"
import courseCompletionsDL from "../redux_DL/reducers/courseCompletion"
import axiomWebhookDL from "../redux_DL/reducers/axiomWebhook"

//Dental XP Reducers below:
import advertisementDXP from '../reduxDXP/reducers/advertisement';
import categoryDXP from '../reduxDXP/reducers/category';
import manufacturerDXP from '../reduxDXP/reducers/manufacturer'
import relatedContentDXP from '../reduxDXP/reducers/relatedContent';
import courseDXP from '../reduxDXP/reducers/course';
import videoDXP from '../reduxDXP/reducers/video';
import webinarDXP from '../reduxDXP/reducers/webinar';
import eventDXP from '../reduxDXP/reducers/event';
import externalEventDXP from '../reduxDXP/reducers/externalEvent';
import articleDXP from '../reduxDXP/reducers/article';
import blogPostDXP from '../reduxDXP/reducers/blogPost'
import languageDXP from '../reduxDXP/reducers/language';
import contentTypeDXP from '../reduxDXP/reducers/contentType';
import userDXP from '../reduxDXP/reducers/user';
import featuredHomeDXP from '../reduxDXP/reducers/featuredHome';
import featuredEventDXP from '../reduxDXP/reducers/featuredEvent';
import featuredAllDXP from '../reduxDXP/reducers/featuredAll';
import featuredFreeDXP from '../reduxDXP/reducers/featuredFree';
import featuredXpertDXP from '../reduxDXP/reducers/featuredXpert';
import featuredExternalEventDXP from '../reduxDXP/reducers/featuredExternalEvent';
import featuredManufacturerDXP from '../reduxDXP/reducers/featuredManufacturer';
import featuredVideoDXP from '../reduxDXP/reducers/featuredVideo';
import featuredCategoryDXP from '../reduxDXP/reducers/featuredCategory';
import featuredSearchDXP from '../reduxDXP/reducers/featuredSearch';
import featuredForumDXP from '../reduxDXP/reducers/featuredForum';
import featuredBannerDXP from '../reduxDXP/reducers/featuredBanner';
import userTypeDXP from '../reduxDXP/reducers/userType';
import contentMapDXP from '../reduxDXP/reducers/contentMap';
import userExternalContentDXP from '../reduxDXP/reducers/userExternalContent';
import memberGroupDXP from '../reduxDXP/reducers/memberGroup'
import productCategoryDXP from '../reduxDXP/reducers/productCategory'
import productManufacturerDXP from '../reduxDXP/reducers/productManufacturer'
import faqDXP from '../reduxDXP/reducers/faq'
import completedCourseDXP from '../reduxDXP/reducers/completedCourse'
import expiringContentDXP from '../reduxDXP/reducers/expiringContent'
import subscriptionsDXP from '../reduxDXP/reducers/stripeSubscription'
import userTransactionsDXP from '../reduxDXP/reducers/userTransaction'
import userSubscriptionsDXP from '../reduxDXP/reducers/userSubscription'
import PendingPostsCommentsDXP from '../reduxDXP/reducers/pendingPostComment'
import page404and301DXP from '../reduxDXP/reducers/404and301'
import mailingListDXP from '../reduxDXP/reducers/mailingList'
import symposiumDXP from '../reduxDXP/reducers/symposium'
import externalContributorDXP from '../reduxDXP/reducers/externalContributor'
import affiliateDXP from '../reduxDXP/reducers/affiliate'
import fellowshipDXP from '../reduxDXP/reducers/fellowship'
import fellowshipTestsDXP from '../reduxDXP/reducers/fellowshipTest'
import fellowshipSessionsDXP from '../reduxDXP/reducers/fellowshipSession'
import fellowshipFacultyDXP from '../reduxDXP/reducers/fellowshipFacultyMember'
import fellowshipAdditionalProgramsDXP from '../reduxDXP/reducers/fellowshipAdditionalProgram'
import fellowshipSubscriptionsDXP from '../reduxDXP/reducers/fellowshipSubscription'
import fellowshipMaterialsDXP from '../reduxDXP/reducers/fellowshipMaterial'
import registrationEventDXP from '../reduxDXP/reducers/registrationEvent'
import registrationUserDXP from '../reduxDXP/reducers/registrationUser'
import subscriptionAuditDXP from '../reduxDXP/reducers/subscriptionAudit'
import trialCodeDXP from '../reduxDXP/reducers/trialCode'
import subscriptionDXP from '../reduxDXP/reducers/subscription'
import popUpsDXP from '../reduxDXP/reducers/popUp'
import freeSubscriptionDXP from '../reduxDXP/reducers/freeSubscription'
import utmMetadataDXP from '../reduxDXP/reducers/utmMetadata'

//Buyers Guide Reducers below:
import buyersGuide from "./buyersGuide";

// export const rootReducer = combineReducers({
export const rootReducer = {
    themeReducer,
    product,
    productReviews,
    category,
    criteria,
    evaluationCriteria,
    article,
    articleType,
    featured,
    manufacturer,
    promotion,
    advertisement,
    auth,
    alert,
    users,
    evaluation,
    productVideo,
    video,
    featuredManufacturer,
    manufacturerReviews,
    productImages,
    distributor,
    productDistributor,
    productSku,
    blog,
    placement,
    Page404And301,
    siteToggle,
    embedForm,
    buyersGuide,
    articleTemplate,
    dynamicPersona,
    newsfeed,
    teamMembers,
    popUps,

    // DL REDUCERS
    course,
    event,
    instructor,
    categoryDL,
    location,
    targetAudience,
    courseType,
    advertisementDL,
    embedFormDL,
    manufacturerDL,
    shortVideosDL,
    usersDL,
    contentFeedDL,
    popupDL,
    featuredDL,
    bundleDL,
    transactionDL,
    subscriptionDL,
    registrationDL,
    bannerDL,
    blogDL,
    articleDL,
    courseLicenseDL,
    freeMembershipDL,
    competitorExclusionDL,
    courseCompletionsDL,
    axiomWebhookDL,

    // DXP REDUCERS
    advertisementDXP,
    categoryDXP,
    manufacturerDXP,
    // subCategoryDXP,
    relatedContentDXP,
    courseDXP,
    videoDXP,
    articleDXP,
    blogPostDXP,
    webinarDXP,
    languageDXP,
    contentTypeDXP,
    userDXP,
    eventDXP,
    externalEventDXP,
    featuredHomeDXP,
    featuredEventDXP,
    featuredXpertDXP,
    featuredExternalEventDXP,
    featuredManufacturerDXP,
    featuredVideoDXP,
    featuredCategoryDXP,
    featuredSearchDXP,
    featuredForumDXP,
    featuredFreeDXP,
    featuredAllDXP,
    featuredBannerDXP,
    userTypeDXP,
    contentMapDXP,
    userExternalContentDXP,
    memberGroupDXP,
    productCategoryDXP,
    productManufacturerDXP,
    faqDXP,
    completedCourseDXP,
    userSubscriptionsDXP,
    userTransactionsDXP,
    subscriptionsDXP,
    expiringContentDXP,
    PendingPostsCommentsDXP,
    page404and301DXP,
    mailingListDXP,
    symposiumDXP,
    externalContributorDXP,
    affiliateDXP,
    fellowshipDXP,
    fellowshipTestsDXP,
    fellowshipSessionsDXP,
    fellowshipFacultyDXP,
    fellowshipAdditionalProgramsDXP,
    fellowshipSubscriptionsDXP,
    fellowshipMaterialsDXP,
    registrationEventDXP,
    registrationUserDXP,
    subscriptionAuditDXP,
    trialCodeDXP,
    subscriptionDXP,
    popUpsDXP,
    freeSubscriptionDXP,
    utmMetadataDXP,
};
